export const sshWebSocketHostUrl =
  ((window as any).ENVIRONMENT?.VITE_APP_WS_SERVER_URL as string) ??
  (import.meta.env.VITE_APP_WS_SERVER_URL as string);

export const firebaseConfig =
  ((window as any).ENVIRONMENT?.VITE_APP_FIREBASE_CONFIG as string) ??
  (import.meta.env.VITE_APP_FIREBASE_CONFIG as string);

export const disableSentry = ((window as any).ENVIRONMENT?.VITE_APP_DISABLE_SENTRY as string) ??
  (import.meta.env.VITE_APP_DISABLE_SENTRY as string) ?? "true" === "true";

export const sshGatewayUrl =
  ((window as any).ENVIRONMENT?.VITE_APP_SSH_GATEWAY_URL as string) ??
  (import.meta.env.VITE_APP_SSH_GATEWAY_URL as string);

export const sshPortalOrigins = (
  ((window as any).ENVIRONMENT?.VITE_APP_SSH_PORTAL_ORIGINS as string) ??
  (import.meta.env.VITE_APP_SSH_PORTAL_ORIGINS as string) ??
  ""
)
  .split(",")
  .filter((origin) => !!origin);

export const hasSSHOrigin = sshPortalOrigins.length > 0;

export const isSSHPortalOrigin = (originToCheck: string) =>
  sshPortalOrigins.includes(originToCheck);





