import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { Centered } from "./Centered";
import { sshGatewayUrl, isSSHPortalOrigin, hasSSHOrigin } from "./constants";
import { queryUserName } from "./queryUserName";
import { SymContextProvider } from "./SymContext";

export const SymAuthGate = ({ children }: PropsWithChildren) => {
  const [username, setUsername] = useState(null);
  const [authenticated, setAuthentication] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onMessage = async (event: MessageEvent) => {
      if (
        event &&
        isSSHPortalOrigin(event.origin) &&
        typeof event.data === "string" &&
        event.data.startsWith("whiteboard-set-user-token:")
      ) {
        setLoading(true);
        const token = event.data.substring("whiteboard-set-user-token:".length);
        queryUserName(token)
          .then((username) => {
            setUsername(username);
            setLoading(false);
            setAuthentication(true);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setAuthentication(false);
          });
      }
    };
    //symmedia custom - use gateway url from global window object
    if (hasSSHOrigin && sshGatewayUrl) {
      window.addEventListener("message", onMessage);
    }

    return () => {
      //symmedia custom - use gateway url from global window object
      if (hasSSHOrigin && sshGatewayUrl) {
        window.removeEventListener("message", onMessage);
      }
    };
  });

  //symmedia custom - use gateway url from global window object
  if (!hasSSHOrigin || !sshGatewayUrl) {
    return <Centered>Missing configuration!</Centered>;
  }

  if (loading) {
    return <Centered>Loading...</Centered>;
  }

  if (!authenticated) {
    return <Centered>Not authenticated!</Centered>;
  }

  return (
    <SymContextProvider value={{ username }}>{children}</SymContextProvider>
  );
};
